import React, { useState } from "react"
import styled from 'styled-components'
import SEO from '../components/SEO.js'
import Navbar from '../components/Navbar.js'
import Footer from '../components/Footer.js'
import Header from '../components/Header.js'
import Backdrop from '../images/banners/contacto.jpg'
import Social from '../components/Social.js'
import { useLocation } from '@reach/router'
import { Alert } from '@material-ui/lab'
import { Collapse, Card, CardContent, Grid, TextField, Button } from '@material-ui/core';

function handleTextFieldChange (e, setDisabled, setfs) {
  if (e.target.value.length < 20) {
    setDisabled(true)
  } else {
    setDisabled(false)
  }

  var expresion = /(\.|dot)(\s+)?(ca|ru|at|gv|de)(\s|$)/i;
  if (e.target.value.match(expresion)) {
    setfs(true)
  }

  var expresion2 = /(google)/i;
  if (e.target.value.match(expresion2)) {
    setfs(true)
  }
}

const ContactPage = () => {
  const location = useLocation()
  const [open, setOpen] = useState(true)
  const [disabled, setDisabled] = useState(true)
  const [fs, setfs] = useState(false)

  if (location.search === "?success") {
    setTimeout(() => { setOpen(false) }, 3000)
  }

  return (
    <Container>
      <SEO title="Contacto" description="Contacto"/>
      <Header title="Contacto" image={Backdrop}/>
      <CustomCard variant="outlined">
        <CardContent>
          <h1 style={{ textAlign: "center", marginBottom: "50px" }}>¿Como podemos ayudarte?</h1>
          <form target="_self" action={fs ? "https://laboratoriosanmartin.cl/contacto?success" : "https://formsubmit.co/84e7659f10cf6bd37ac1e165ab257d32"} method="POST">
            <Grid container spacing={1}>
              <Grid xs={12} sm={6} item>
                <TextField style={{zIndex: 0}}
                type="text" name="name" label="Nombre completo" placeholder="Nombre Apellido" variant="outlined" fullWidth required/>
              </Grid>
              <Grid xs={12} sm={6} item>
                <TextField style={{zIndex: 0}}
                type="email" name="email" label="Correo Electrónico" placeholder="ejemplo@correo.com" variant="outlined" fullWidth required/>
              </Grid>
              <Grid xs={12} item>
                <TextField style={{zIndex: 0}}
                name="Mensaje" multiline rows={4} label="Mensaje" variant="outlined" fullWidth required
                onChange={(e) => handleTextFieldChange(e, setDisabled, setfs)}
                helperText="Mínimo 20 caracteres"/>
              </Grid>
              <Grid style={{ marginTop: "20px" }} xs={12} item container alignItems="center" justify="center">
                <Button disabled={disabled} type="submit" variant="contained" color="primary">Enviar</Button>
              </Grid>
            </Grid>
            <input type="hidden" name="_next" value="https://laboratoriosanmartin.cl/contacto/?success"/>
            <input type="hidden" name="_captcha" value="false"/>
          </form>
        </CardContent>
      </CustomCard>
      <Social/>
      <Footer/>
      <Navbar/>
      {location.search === "?success" ?
        <AlertContainer>
          <Collapse in={open}>
            <Alert severity="success">
            El mensaje ha sido enviado correctamente</Alert>
          </Collapse>
        </AlertContainer>
       : ""}
    </Container>
  )
}

export default ContactPage

const Container = styled.div`
  font-family: Century Gothic,CenturyGothic,sans-serif;
  margin: -8px;
  padding-top: 93px;
`

const CustomCard = styled(Card)`
  margin-top: 50px;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 75px;

  @media only screen and (max-width: 700px) {
      margin-top: 25px;
      margin-left: 5%;
      margin-right: 5%;
      margin-bottom: 75px;
  	}
`

const AlertContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 93px;
  background-color: white;
`
